.App {
  display: grid;
  grid-template-rows: repeat(40, 1fr);
  grid-auto-flow: column;
  height: 100vh;
  overflow: hidden;
}
.square {
  width: 2.5vh;
  height: 2.5vh;
  outline: 0.01vh black solid;
  cursor: pointer;
  font-size: 2vh;
}